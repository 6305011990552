import { BrowserRouter as Router, Route , Routes} from "react-router-dom"
import Landingpage from './pages/Landingpage';
import Profile from './pages/Profile';
import baseUrl from './constants/urls'
import Admin from "./pages/Admin";
import Blogs from "./pages/Blogs";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={`${baseUrl}`} element={<Landingpage />} />
        <Route path={`${baseUrl}profile`} element={<Profile />} />
        <Route path={`${baseUrl}admin`} element={<Admin />}/>
        <Route path={`${baseUrl}blogs`} element={<Blogs />}/>
        <Route path={`${baseUrl}*`} element={<p>404</p>} />
      </Routes>
    </Router>
  );
}

export default App;
