import {useState} from "react";
import {
  Spacer,
  Button,
  MenuItem,
  Image,
  Show
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import baseUrl from '../../constants/urls'
import NavbarContainer from "./NavbarContainer";
import {useAuth} from '../../context/AuthContext'
import ProfileObj from "./ProfileObj";
import source from '../../static/images/Pearmock logo.svg'
import logo from '../../static/images/logo.svg'

function NavBar({signUp,setloading}) {
  const navigate = useNavigate();
  const {currentUser,signInwithGoogle,signOutUser} = useAuth()  
  const [shadow, setshadow] = useState(false)
  function logout(){
    signOutUser()
  }

  function scrollShadow(){
    if(window.scrollY>10){
      setshadow(true)
    }
    else{
      setshadow(false)
    }
  }

  window.addEventListener('scroll',scrollShadow)

  return (
    <>
      <NavbarContainer
        boxShadow={shadow ? "0px 2px 15px rgba(0, 0, 0, 0.05)" : null}
      >
        <Show breakpoint="(min-width: 768px)"><Image src={source} w={"150px"} h={"50px"} ml={"32px"} cursor={"pointer"} onClick={()=>{navigate(`${baseUrl}`)}}/></Show>
        <Show breakpoint="(max-width: 767px)"><Image src={logo} w={"34px"} h={"60px"} ml={"32px"} cursor={"pointer"} onClick={()=>{navigate(`${baseUrl}`)}}/></Show>
        <Spacer />
        {currentUser ? (
          <ProfileObj
            imageUrl={currentUser.photoURL}
            name={currentUser.displayName}
          >
            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              onClick = {()=>{window.open('https://faq.pearmock.com','_blank')}}
            >
              FAQs
            </MenuItem>

            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              onClick={logout}
            >
              Log Out
            </MenuItem>
          </ProfileObj>
        ) : (
          <>
            <Button
              colorScheme={"primary"}
              w="100px"
              h="40px"
              mr={"24px"}
              onClick={() => {
                signInwithGoogle().then(() => {
                  setloading(true);
                  localStorage.setItem("user", true);
                  navigate(`${baseUrl}admin`)
                });
              }}
            >
              Sign In
            </Button>
          </>
        )}
      </NavbarContainer>
    </>
  );
}


export default NavBar;
