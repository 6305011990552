import {useState,useEffect} from 'react'
import Anonymous from '../components/AppBar/Anonymous'
import {
  Center,
  useDisclosure,
  Text,
  Image,
} from "@chakra-ui/react";
import baseUrl from '../constants/urls'
import {useNavigate} from 'react-router-dom'
import Loader from '../static/Loader';
import { useAuth } from '../context/AuthContext';
import source from '../static/images/Admin_landing.jpg'

function Landingpage() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { currentUser, signInwithGoogle, userStatus } = useAuth();
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);

    useEffect(() => {
      if (!localStorage.getItem("user")) {
        setloading(false);
      }
      else{
        navigate(`${baseUrl}admin`)
      }
    }, []);
      
      
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Anonymous signUp={onOpen} setloading={setloading} />
            <Center>
              <Image src={source} w={['295px','295px','700px','700px','1000px','1000px']} h={'auto'} mt={'48px'}/>
            </Center>
            <Center>
              <Text fontSize={'32px'} lineHeight={'120%'} fontWeight={'700'} mt={'16px'}>Admin Dashboard</Text>
            </Center>
          </>
        )}
      </>
    );
}

export default Landingpage
