import {useEffect,useState} from 'react';
import {useAuth} from '../context/AuthContext'
import Loader from "../static/Loader";
import {
  Button,
  Heading,
  VStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Center,
  Avatar,
  Text,
  HStack
} from "@chakra-ui/react";
import {useNavigate} from 'react-router-dom'
import baseUrl from '../constants/urls';
import {db} from '../firebase'
import {onSnapshot,collection,query,where} from 'firebase/firestore'
import AdminCard from '../components/Admin/AdminCard';
import Authenticated from '../components/AppBar/Authenticated'
import { level_name_map } from '../constants/level';

function Admin() {
  const navigate = useNavigate()
  const {user} = useAuth()
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  useEffect(() => {
    if(user !== null){
        if(user.isAdmin){
            setloading(false)
            fetchData();
        }
        else{
            navigate(`${baseUrl}`)
        }
    }
  }, [user]);
  
  function fetchData(){
    const usersRef = collection(db,'users')
    const q = query(usersRef)
    onSnapshot(q, (querySnap) => {
        setdata(
          querySnap.docs.map(doc=>{
            return {
                id: doc.id,
                ...doc.data()
            }
          })
        )
      });
  }

  // useEffect(() => {
  //   console.log(data)
  // }, [data]);
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Authenticated setloading={setloading} />
          <Center><Heading mt={'48px'} mb={'24px'}>Users</Heading></Center>
          <Center>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>NAME</Th>
                    <Th>PROFILE STATUS</Th>
                    <Th isNumeric># CREATED MOCKS</Th>
                    <Th isNumeric># UPCOMING MOCKS</Th>
                    <Th isNumeric># PAST MOCKS</Th>
                    <Th isNumeric># COMPLETED MOCKS</Th>
                    <Th>USER LEVEL</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.length ? (
                    <>
                      {data.map((doc) => {
                        return (
                          <>
                            {doc.userStatus === "unverified" ? (
                              <Tr key={doc.id}>
                                <Td cursor={'pointer'} onClick={()=>{navigate(`${baseUrl}profile`,{ state: doc})}}>
                                  <HStack spacing={"16px"}>
                                    <Avatar
                                      size={"xs"}
                                      src={doc.photoUrl}
                                      name={doc.firstName}
                                    />
                                    <Text>
                                      {doc.displayName}
                                    </Text>
                                  </HStack>
                                </Td>
                                <Td>{doc.userStatus}</Td>
                              </Tr>
                            ) : (
                              <Tr key={doc.id}>
                                <Td cursor={'pointer'} onClick={()=>{navigate(`${baseUrl}profile`,{ state: doc })}}>
                                  <HStack spacing={"16px"}>
                                    <Avatar
                                      size={"xs"}
                                      src={doc.avatarUrl}
                                      name={doc.firstName}
                                    />
                                    <Text>
                                      {doc.firstName} {doc.lastName}
                                    </Text>
                                  </HStack>
                                </Td>
                                <Td>{doc.userStatus}</Td>
                                <Td>{Object.keys(doc.createdMocks).length}</Td>
                                <Td>{Object.keys(doc.upcomingMocks).length}</Td>
                                <Td>{doc.pastMocks}</Td>
                                <Td>{doc.mocksCompleted}</Td>
                                <Td>{level_name_map.get(doc.level)}</Td>
                              </Tr>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>
          </Center>
        </>
      )}
    </>
  );
}

export default Admin;
