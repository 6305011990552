import {Box,HStack} from '@chakra-ui/react'
function MenuLinks({children}) {
  return (
    <Box>
      <HStack>
        {children}
      </HStack>
    </Box>
  );
}

export default MenuLinks;
