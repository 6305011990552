import React, {useState, useEffect} from 'react'
import Authenticated from '../components/AppBar/Authenticated'
import { useLocation, useNavigate } from 'react-router-dom';
import baseUrl from '../constants/urls';
import Loader from '../static/Loader';
import { Center, Heading, Box, HStack, Avatar, Text, Spacer, Tag, Button } from '@chakra-ui/react';
import { level_name_map, level_point_map } from '../constants/level';
import {useAuth} from '../context/AuthContext'
import {doc,setDoc,updateDoc} from 'firebase/firestore'
import {httpsCallable} from 'firebase/functions'
import {db,functions} from '../firebase'

function Profile() {
  const {user} = useAuth()
  const [loading, setloading] = useState(true);
  const [loader, setloader] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state;
  useEffect(() => {
    if (user !== null) {
      if (user.isAdmin) {
        if (state !== undefined || state !== null) {
          setloading(false);
        } else {
          navigate(`${baseUrl}admin`);
        }
      } else {
        navigate(`${baseUrl}`);
      }
    }
  }, [user, state]);
  
  function toDate(datetime){
    const ts = (datetime.seconds + datetime.nanoseconds*10**-9)*1000
    const date = new Date(ts)
    return `${date.toDateString()}, ${date.getHours()}:${date.getMinutes()}`
  }

  function updateLevel(levelMock){
    var level;
    for(var [key,value] of level_point_map){
      if(levelMock >= value){
        level = key;
      }
    }
    return level;
  }

  function levelMock(exp) {
    if (exp === "1-3 months" || exp === "3+ months") {
      return 0;
    } else if (exp === "no experience") {
      return 0;
    } else {
      return 60;
    }
  }

  function verify(){
    setloader(true)
    setDoc(doc(db,'users',state.id),{
        userStatus: 'verified',
        verifiedAt: new Date(),
        level: updateLevel(levelMock(state.workExperience)),
        levelMock: levelMock(state.workExperience)
    },{merge:true}).then(() => {
      const sendemail = httpsCallable(functions, "sendemail");
      sendemail({
        to: `${state.email}`,
        subject: `Welcome to Pearmock ${state.firstName} ${state.lastName}`,
        text: "We welcome you to Pearmock",
        html: `Hello ${state.firstName},
        <br/>
        <br/>
        Welcome to <b>Pearmock</b>! We're thrilled to have you join our vibrant community of ambitious professionals and students. You've taken the first step towards acing your case interviews, and we're here to support you every step of the way.
        <br/>
        <br/>
        Remember, the key to success is consistency. Schedule regular practice sessions, actively participate in interviews, and don't shy away from giving or receiving feedback. Every interview is a learning opportunity.
        <br/>
        <br/>
        If you have any questions or need any help, feel free to reach out to us. We're here to support you on your journey.
        <br/>
        <br/>
        Now, we'd love to get to know you better. Reply to this email and tell us something interesting about yourself. It could be a fun fact, a hobby, or even your favorite book. This will help us ensure that our conversation is recognized as a genuine human interaction by the email gods.
        <br/>
        <br/>
        Ready to get started? <a href="https://peramock.com">Log in to your account</a> and schedule your first mock interview today!
        <br/>
        <br/>
        Happy practicing,
        <br/>
        <br/>
        Krishna
        <br/>
        Pearmock team`,
      }).finally(()=>{
        setloader(false)
        navigate(`${baseUrl}admin`)
      });
    });
}

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Authenticated setloading={setloading} />
          <Center mt={"48px"}>
            <Heading>Profile</Heading>
          </Center>
          <Center mt={"24px"}>
            <Box
              borderRadius={"24px"}
              background={"#E5F09B"}
              w={"1211px"}
              h={"584px"}
              p={"40px"}
            >
              <HStack>
                <HStack spacing={"24px"}>
                  <Avatar src={state.avatarUrl? state.avatarUrl: state.photoUrl} size={"2xl"} />
                  <Box>
                    <Text
                      fontSize={"32px"}
                      lineHeight={"150%"}
                      fontWeight={"700"}
                    >
                     {state.displayName}
                    </Text>
                    <Text
                      fontSize={"20px"}
                      lineHeight={"150%"}
                      fontWeight={"500"}
                      mt={"2px"}
                    >
                      {state.bio? state.bio:null}
                    </Text>
                    <Text
                      fontSize={"20px"}
                      lineHeight={"150%"}
                      fontWeight={"500"}
                      mt={"10px"}
                    >
                      {state.level? level_name_map.get(state.level):null}
                    </Text>
                  </Box>
                </HStack>
                <Spacer />
                <HStack>
                  <Tag color={"white"} background={"red.500"} mr={'16px'}>
                    Status: {state.userStatus}
                  </Tag>
                  {state.userStatus === "verified" || state.userStatus === "unverified"? (
                    <Button
                      size={"sm"}
                      color={"red"}
                      variant={"unstyled"}
                      disabled={true}
                    >
                      No actions
                    </Button>
                  ) : (
                    <Button size={"sm"} color={"red"} variant={"unstyled"} onClick={verify} isLoading={loader}>
                      Verify user
                    </Button>
                  )}
                </HStack>
              </HStack>
              <HStack mt={'36px'} spacing={'24px'}>
                <Box p={'12px'} h={'344px'} background={'white'} borderRadius={'24px'}> 
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>HEADER</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>VALUE</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Created at</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.signedAt ? toDate(state.signedAt): `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Submitted at</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.submittedAt ? toDate(state.submittedAt): `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Verified at</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.verifiedAt ? toDate(state.verifiedAt): `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}># mocks created</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.createdMocks ? Object.keys(state.createdMocks).length: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}># mocks completed</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.mocksCompleted !== undefined ? state.mocksCompleted: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}># mocks cancelled</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.cancelledMocks !== undefined? state.cancelledMocks: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Mock Level</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.levelMock ? state.levelMock: `null`}</Text>
                  </HStack>
                </Box>
                <Box p={'12px'} h={'344px'} background={'white'} borderRadius={'24px'}> 
                <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>HEADER</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>VALUE</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Linkedin</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.linkedinUrl ? state.linkedinUrl: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Email</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.email ? state.email: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Target Companies</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.targetCompanies ? state.targetCompanies.join(', '): `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Work experience</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.workExperience ? state.workExperience: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Education</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.educationDegree ? state.educationDegree: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Graduation year</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.graduationYear ? state.graduationYear: `null`}</Text>
                  </HStack>
                  <HStack>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'} w={'181px'}>Current company</Text>
                    <Text px={'24px'} py={'12px'} fontSize={'12px'} lineHeight={'16px'} fontWeight={'800'}>{state.currentCompany ? state.currentCompany: `null`}</Text>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          </Center>
        </>
      )}
    </>
  );
}

export default Profile