import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getFunctions} from 'firebase/functions'
const firebaseConfig = {
  apiKey: "AIzaSyBxA0Vmf4NSrm4AXYYPDzT7kfduUrH1Kr8",
  authDomain: "pearmock-f75c3.firebaseapp.com",
  projectId: "pearmock-f75c3",
  storageBucket: "pearmock-f75c3.appspot.com",
  messagingSenderId: "237007590414",
  appId: "1:237007590414:web:5674f16a0e479d13d6d85f",
  measurementId: "G-N1ZMRTZHXW"
};


const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
const functions = getFunctions(firebaseApp);
export {db,auth,functions};