import React from 'react'
import { Box, Flex, Center, Heading, Input, Text, Link, Button, Spinner, HStack } from '@chakra-ui/react'
import { db } from '../firebase';
import { query, collection, orderBy, onSnapshot, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import Authenticated from "../components/AppBar/Authenticated";
import { useAuth } from '../context/AuthContext';
import { useState } from 'react';

const BlogsCard = ({ title, link, imageUrl, handleEdit, handleDelete, index, isDeleting }) => {
    return (
        <Flex
            flexDirection={'column'}
            gap={5}
            w={{ base: '100%', md: '50%' }}
            p={'32px'}
            bg={'#F2F6D9'}
            color={'blackAlpha.700'}
            borderRadius={'24px'}

        >

            <Text
                wordBreak={'break-all'}
            >
                Title: {title}
            </Text>
            <Text
                wordBreak={'break-all'}
            >
                Link: {link}
            </Text>



            <Link
                href={imageUrl}
                isExternal
            >
                View Image
            </Link>

            <HStack
                alignSelf={'flex-end'}
            >
                <Button
                    onClick={() => { handleEdit(index) }}
                    colorScheme='blue'
                >
                    Edit
                </Button>
                <Button
                    onClick={() => { handleDelete(index) }}
                >
                    {isDeleting ? <Spinner /> : 'Delete'}
                </Button>
            </HStack>
        </Flex>
    )
};
const AddBlogDataForm = ({ blogData, setBlogData, handleUpdate, handleAdd, isLoading, isEditing }) => {


    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditing)
            handleUpdate();
        else
            handleAdd();
    }
    const handleInput = (e) => {
        const { name, value } = e.target;
        setBlogData(prevData => ({ ...prevData, [name]: value }));
    }

    return (
        <Box
            w={{ base: '100%', md: '50%' }}
            p={'32px'}
            bg={'#F2F6D9'}
            color={'blackAlpha.700'}
            borderRadius={'24px'}
        >
            <Heading
                mb={'32px'}
            >
                Add a new blog
            </Heading>
            <form
                onSubmit={handleSubmit}
                style={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}
            >
                <Input
                    placeholder='Title'
                    variant='filled'
                    onChange={handleInput}
                    name='title'
                    value={blogData.title}
                />
                <Input
                    placeholder='Link'
                    variant='filled'
                    onChange={handleInput}
                    name='link'
                    value={blogData.link}
                />
                <Input
                    placeholder='ImageUrl'
                    variant='filled'
                    onChange={handleInput}
                    name='imageUrl'
                    value={blogData.imageUrl}
                />
                <Button
                    type='submit'
                >
                    {isLoading ? <Spinner /> : isEditing ? 'Edit Data' : 'Add Blog Data'}
                </Button>
            </form>
        </Box>
    )
}
export default function Blogs() {
    const [blogs, setBlogs] = React.useState([]);
    const { currentUser, user } = useAuth();
    const [isDeleting, setIsDeleting] = React.useState(false); // [false, function
    const [isEditing, setIsEditing] = React.useState(false);
    const [blogData, setBlogData] = React.useState({ title: '', link: '', imageUrl: '' });
    const [isLoading, setIsLoading] = useState(false);
    const scrollToTopRef = React.useRef(null);
    const handleEdit = async (index) => {
        setIsEditing(true);
        setBlogData(blogs[index]);
        scrollToTopRef.current?.scrollIntoView({ behavior: 'smooth' });


    };
    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const docRef = doc(db, 'blogs', blogData.id);
            await updateDoc(docRef, { ...blogData, createdAt: new Date() });
            const filterData = blogs.filter(blog => blog.id !== blogData.id);
            setBlogs([blogData, ...filterData]);
            setBlogData({ title: '', link: '', imageUrl: '' });

        } catch (error) {
            console.log('Error updating blog', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleDelete = async (index) => {

        const isSure = window.confirm('Are you sure you want to delete this blog?');
        if (!isSure) return;
        setIsDeleting(true);
        try {
            await deleteDoc(doc(db, 'blogs', blogs[index].id));
            const fileredBlogs = blogs.filter((blog, i) => i !== index);
            setBlogs(fileredBlogs);
        } catch (error) {
            console.log('Error deleting blog', error);
        } finally {
            setIsDeleting(false);
        }

    }
    const handleAdd = async () => {
        setIsLoading(true);
        try {
            const blogRef = await addDoc(collection(db, 'blogs'), { ...blogData, createdAt: new Date() });
            setBlogs([{ ...blogData, id: blogRef.id }, ...blogData]);
            setBlogData({ title: '', link: '', imageUrl: '' });
        } catch (error) {
            console.log('Error adding blog data to DB', error);
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        if (!currentUser) return;
        const getBlogs = async () => {
            try {
                const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const blogs = [];
                    querySnapshot.forEach((doc) => {
                        blogs.push({ ...doc.data(), id: doc.id });
                    });
                    setBlogs(blogs);
                });
                return unsubscribe;
            } catch (error) {
                console.log('Error getting blogs', error);
            }
        }
        getBlogs();
    }, [currentUser, user]);
    return (
        <>
            <Authenticated />
            <Center
                pb={["10px", "64px", "64px", "192px", "192px"]}
                pt={["10px", "10px", "10px", "48px", "48px"]}
                px={'32px'}
                display={'flex'}
                flexDirection={'column'}
                gap={10}
                maxW={'100vw'}
            >
                <Heading>
                    Blogs
                </Heading>
                <div ref={scrollToTopRef}></div>
                <AddBlogDataForm
                    blogData={blogData}
                    setBlogData={setBlogData}
                    handleAdd={handleAdd}
                    handleUpdate={handleUpdate}
                    isLoading={isLoading}
                    isEditing={isEditing}
                />
                {
                    blogs.map((blog, index) => {
                        return (
                            <BlogsCard
                                key={`${index}-${blog.id}`}
                                {...blog}
                                setIsEditing={setIsEditing}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                index={index}
                                isDeleting={isDeleting}
                            />
                        )
                    })
                }
            </Center>
        </>
    )
}
