import {
  Spacer,
  Button,
  MenuItem,
  Show,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Avatar,
  Text,
  useDisclosure,
  HStack,
  Box,
  Image,
  useToast
} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons"
import {useNavigate} from 'react-router-dom'
import baseUrl from '../../constants/urls'
import NavbarContainer from "./NavbarContainer";
import MenuLinks from "./MenuLinks";
import ProfileObj from "./ProfileObj";
import source from '../../static/images/Pearmock logo.svg'
import logo from '../../static/images/logo.svg'
import {useAuth} from '../../context/AuthContext'


function NavBar({setloading}){
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {user,signOutUser} = useAuth()
  const navigate = useNavigate();  
  const toast = useToast();

 
  return (
    <>
      <NavbarContainer>
        <Show breakpoint="(min-width: 768px)">
          <Show breakpoint="(min-width: 769px)">
            <Image
              src={source}
              w={"150px"}
              h={"50px"}
              ml={"32px"}
              cursor={"pointer"}
              onClick={() => {
                navigate(`${baseUrl}admin`);
              }}
              mr={"45px"}
              mt={"-6px"}
            />
          </Show>
          <Show breakpoint="(max-width: 769px)">
            <Image
              src={logo}
              w={"34px"}
              h={"60px"}
              ml={"32px"}
              cursor={"pointer"}
              onClick={() => {
                navigate(`${baseUrl}admin`);
              }}
              mr={"15px"}
              mt={"-6px"}
            />
          </Show>
          <MenuLinks>
            <Button
              variant={"ghost"}
              colorScheme="gray"
              color="gray.700"
              px={5}
              fontSize={20}
              lineHeight={24}
              onClick={() => {
                navigate(`${baseUrl}admin`);
              }}
            >
              Users
            </Button>
            <Button
              variant={"ghost"}
              colorScheme="gray"
              color="gray.700"
              px={5}
              fontSize={20}
              lineHeight={24}
              onClick={() => {
                navigate(`${baseUrl}blogs`);
              }}
            >
              Blogs
            </Button>
            <Button
              variant={"ghost"}
              colorScheme="gray"
              color="gray.700"
              px={5}
              fontSize={20}
              lineheight={24}
              onClick={() => {
                window.open('https://resources.pearmock.com','_blank')
              }}
            >
              Resources
            </Button>
          </MenuLinks>
          <Spacer />
          <ProfileObj imageUrl={user?.avatarUrl} name={user?.firstName}>
            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              bg={"#FEEBCB"}
            >
              Rulebook
            </MenuItem>

            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              bg={"#FEEBCB"}
              onClick={() => {window.open('https://faq.pearmock.com','_blank')}}
            >
              FAQs
            </MenuItem>

            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              bg={"#FEEBCB"}
            >
              Contact Us
            </MenuItem>

            <MenuItem
              pl="17px"
              py="12px"
              minH="0"
              h="46px"
              color="black"
              fontSize="14px"
              lineHeight="20px"
              fontWeight={400}
              bg={"#FEEBCB"}
              onClick={() => {
                setloading(true);
                signOutUser()
                  .then(() => {
                    navigate(`${baseUrl}`);
                  })
                  .catch((err) => {
                    alert(err.message);
                  });
              }}
            >
              Logout
            </MenuItem>
          </ProfileObj>
        </Show>
        <Show breakpoint="(max-width: 767px)">
          <IconButton
            icon={<HamburgerIcon w={"24px"} h={"24px"} color={"black"} />}
            colorScheme={"gray"}
            variant={"unstyled"}
            onClick={onOpen}
          />
          <Spacer />
          <Image
            src={logo}
            w={"34px"}
            h={"60px"}
            ml={"32px"}
            cursor={"pointer"}
            onClick={() => {
              navigate(`${baseUrl}admin`);
            }}
            mr={"24px"}
            mt={"-6px"}
          />
        </Show>
      </NavbarContainer>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth={"1px"}>
            <HStack spacing={"16px"}>
              <Avatar
                h={"40px"}
                w={"40px"}
                src={user?.avatarUrl}
                name={user?.firstName}
              />
              <Text
                fontSize={"20px"}
                lineHeight={"28px"}
                fontWeight={"500"}
              >{`${user?.firstName} ${user?.lastName}`}</Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody borderBottomWidth={"1px"}>
            <Box ml={"8px"} mt={"20px"}>
              <Box h={"40px"} onClick={() => navigate(`${baseUrl}admin`)}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"400"}
                  mb={"8px"}
                >
                  Users
                </Text>
              </Box>
              <Box h={"40px"} onClick={() => {window.open("https://resources.pearmock.com","_blank")}}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"400"}
                  my={"8px"}
                >
                  Resources
                </Text>
              </Box>
              <Box h={"40px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"400"}
                  my={"8px"}
                >
                  Rulebook
                </Text>
              </Box>
              <Box h={"40px"} onClick = {()=>{window.open('https://faq.pearmock.com','_blank')}}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"400"}
                  my={"8px"}
                >
                  FAQs
                </Text>
              </Box>
              <Box h={"40px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"400"}
                  my={"8px"}
                >
                  Contact us
                </Text>
              </Box>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Box
              ml={"8px"}
              w={"full"}
              onClick={() => {
                setloading(true);
                signOutUser()
                  .then(() => {
                    navigate(`${baseUrl}`);
                  })
                  .catch((err) => {
                    alert(err.message);
                  });
              }}
            >
              <Text fontSize={"16px"} lineHeight={"24px"} fontWeight={"400"}>
                Log out
              </Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NavBar;
