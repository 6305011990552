import {createContext,useContext,useEffect,useState} from 'react'
import { db,auth } from '../firebase'
import {GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut} from 'firebase/auth'
import {doc,setDoc,onSnapshot} from 'firebase/firestore'
const AuthContext = createContext()

export const useAuth = ()=> useContext(AuthContext)

export default function AuthContextProvider({children}){
    const [currentUser, setcurrentUser] = useState(null);
    const [userStatus, setuserStatus] = useState(null);
    const [user, setuser] = useState(null);
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, user=>{
          if(user){
            localStorage.setItem('user',true)
            setcurrentUser(user.providerData[0])
            onSnapshot(doc(db,'users',user.providerData[0].uid),(docSnap)=>{
              if(docSnap.exists()){
                setuserStatus(docSnap.data().userStatus)
                setuser(docSnap.data())
              }
              else{
                setDoc(doc(db,'users',user.providerData[0].uid),{
                  ...user.providerData[0],
                  signedAt: new Date(),
                  userStatus: 'unverified'
                },{merge:true})
                setuserStatus('unverified')
              }
            })           
          }
          else{
            setcurrentUser(user)
            setuser(null)
            setuserStatus(null)
            localStorage.removeItem('user')
          }
          
      })
    
      return () => {
        unsubscribe();
      };
    }, []);
    

    function signInwithGoogle(){
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }

    function signOutUser(){
        return signOut(auth)
    }

    function status(statusString){
      setuserStatus(statusString)
    }

    const value = {
        currentUser,
        userStatus,
        user,
        signInwithGoogle,
        signOutUser,
        status
    }
    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}